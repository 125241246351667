<template>
  <div class="markup-tables flex">
    <va-card class="flex xs12 mb-4" v-if="image">
      <va-card-title style="font-size: 1.5rem;">
        {{ image.name }}
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; cursor: pointer;" @click="downloadFiles" />
        <va-button size="small" class="mr-2 mb-2" color="success" style="margin-left: 1rem; margin-bottom: 0 !important; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#configureFileModal">Analytics</va-button>
      </va-card-title>
    </va-card>

    <div v-viewer="options" class="row" v-if="image && image.cropping !== 'done'">
      <template v-for="(image,index) in images" :key="index">
        <va-card class="flex xs4 md4 offset--md4">
          <va-content>
            <img
              :src="image.thumbnail" class="image"
              :data-src="image.source"
              :alt="image.title"
            >
          </va-content>
          <va-card-content style="text-align: center;">
            {{ image.title }}
          </va-card-content>
        </va-card>
      </template>
    </div>
    <div v-viewer="options" class="row" v-else-if="image && image.image_processing !== 'done'">
      <template v-for="(image,index) in images" :key="index">
        <va-card class="flex xs4 md4 offset--md2" v-if="index === 0">
          <va-content>
            <img
              :src="image.thumbnail" class="image"
              :data-src="image.source"
              :alt="image.title"
            >
          </va-content>
          <va-card-content style="text-align: center;">
            {{ image.title }}
          </va-card-content>
        </va-card>
        <va-card class="flex xs4 md4" v-else>
          <va-content>
            <img
              :src="image.thumbnail" class="image"
              :data-src="image.source"
              :alt="image.title"
            >
          </va-content>
          <va-card-content style="text-align: center;">
            {{ image.title }}
          </va-card-content>
        </va-card>
      </template>
    </div>
    <div v-viewer="options" class="row" v-else-if="image">
      <template v-for="(image,index) in images" :key="index">
        <va-card class="flex xs3 md3">
          <va-content>
            <img
              :src="image.thumbnail" class="image"
              :data-src="image.source"
              :alt="image.title"
            >
          </va-content>
          <va-card-content style="text-align: center;">
            {{ image.title }}
          </va-card-content>
        </va-card>
      </template>
    </div>

    <va-collapse
      header="General Stats"
      flat
      color="white"
      class="w-96"
      style="margin-bottom: 1.5rem;"
      v-model="collapse_values[0]"
      v-if="image && image.image_processing === 'done'"
    >
      <va-card class="flex xs12 mb-4" v-if="image && image.image_processing === 'done'">
        <va-card-content>
          <div class="flex md12">
            <table id="stat-table" style="width: 50%;">
              <tr v-if="image.image_processing === 'done'">
                <td>Melanin Left Under Eye</td>
                <td>{{ image.mel_lduec }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Melanin Right Under Eye</td>
                <td>{{ image.mel_rduec }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Melanin Left Cheek</td>
                <td>{{ image.mel_lcheek }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Melanin Right Cheek</td>
                <td>{{ image.mel_rcheek }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Melanin Mean</td>
                <td>{{ image.mel_mean }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Melanin Standard Deviation</td>
                <td>{{ image.mel_sd }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Hemoglobin Left Under Eye</td>
                <td>{{ image.hem_lduec }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Hemoglobin Right Under Eye</td>
                <td>{{ image.hem_rduec }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Hemoglobin Left Cheek</td>
                <td>{{ image.hem_lcheek }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Hemoglobin Right Cheek</td>
                <td>{{ image.hem_rcheek }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Hemoglobin Mean</td>
                <td>{{ image.hem_mean }}</td>
              </tr>
              <tr v-if="image.image_processing === 'done'">
                <td>Hemoglobin Standard Deviation</td>
                <td>{{ image.hem_sd }}</td>
              </tr>
            </table>
          </div>
        </va-card-content>
      </va-card>
    </va-collapse>

    <div v-viewer="options" class="row" v-if="image && image.image_pigment === 'done'">
      <template v-for="(image,index) in pigment_images" :key="index">
        <va-card class="flex xs4 md4">
          <va-content>
            <img
              :src="image.thumbnail" class="image"
              :data-src="image.source"
              :alt="image.title"
            >
          </va-content>
          <va-card-content style="text-align: center;">
            {{ image.title }}
          </va-card-content>
        </va-card>
      </template>
    </div>

    <va-collapse
      header="Pigment Stats"
      flat
      color="white"
      class="w-96"
      style="margin-bottom: 1.5rem;"
      v-model="collapse_values[1]"
      v-if="image && image.image_pigment === 'done'"
    >
      <va-card class="flex xs12 mb-4" v-if="image && image.image_pigment === 'done'">
        <va-card-content>
          <p>Pigmented Area count is: {{ pigment_csv.length }}</p>
          <div class="flex md12" style="max-height: 360px; overflow-y: scroll;">
            <table id="pigment-table" style="width: 50%;">
              <th>
                <td>label</td>
              </th>
              <th>
                <td>area</td>
              </th>
              <th>
                <td>equivalent_diameter</td>
              </th>
              <th>
                <td>mean_intensity</td>
              </th>
              <th>
                <td>solidity</td>
              </th>
              <th>
                <td>Probabilty</td>
              </th>
              <th>
                <td>areaSizeSensor</td>
              </th>
              <th>
                <td>area_sqmm</td>
              </th>
              <tr v-for="row in pigment_csv">
                <td v-for="cell in row">
                  {{ cell }}
                </td>
              </tr>
            </table>
          </div>
        </va-card-content>
      </va-card>
    </va-collapse>

    <div v-viewer="options" class="row" v-if="image && image.image_acne === 'done'">
      <va-card class="flex xs4 md4">
        <va-content>
          <img
            :src="acne_image.thumbnail" class="image4"
            :data-src="acne_image.source"
            :alt="acne_image.title"
          >
        </va-content>
        <va-card-content style="text-align: center;">
          {{ acne_image.title }}
        </va-card-content>
      </va-card>
      <template v-for="(image,index) in right_acne_images" :key="index">
        <va-card class="flex xs4 md4">
          <va-content>
            <img
              :src="image.thumbnail" class="image2"
              :data-src="image.source"
              :alt="image.title"
            >
            <img
              :src="left_acne_images[index].thumbnail" class="image3"
              :data-src="left_acne_images[index].source"
              :alt="left_acne_images[index].title"
            >
          </va-content>
          <va-card-content style="text-align: center;">
            {{ image.title }}
          </va-card-content>
        </va-card>
      </template>
    </div>

    <va-collapse
      header="Right Cheek Acne Stats"
      flat
      color="white"
      class="w-96"
      v-model="collapse_values[2]"
      v-if="image && image.image_acne === 'done'"
    >
      <va-card class="flex xs12 mb-4" v-if="image && image.image_acne === 'done'">
        <va-card-content>
          <p>Right Cheek Acne Area count is: {{ right_acne_csv.length }}</p>
          <div class="flex md12" style="max-height: 360px; overflow-y: scroll;">
            <table id="pigment-table" style="width: 50%;">
              <th>
                <td>label</td>
              </th>
              <th>
                <td>area</td>
              </th>
              <th>
                <td>equivalent_diameter</td>
              </th>
              <th>
                <td>mean_intensity</td>
              </th>
              <th>
                <td>solidity</td>
              </th>
              <th>
                <td>Probabilty</td>
              </th>
              <th>
                <td>areaSizeSensor</td>
              </th>
              <th>
                <td>area_sqmm</td>
              </th>
              <tr v-for="row in right_acne_csv">
                <td v-for="cell in row">
                  {{ cell }}
                </td>
              </tr>
            </table>
          </div>
        </va-card-content>
      </va-card>
    </va-collapse>

    <va-collapse
      header="Left Cheek Acne Stats"
      flat
      color="white"
      class="w-96"
      style="margin-bottom: 1.5rem;"
      v-model="collapse_values[3]"
      v-if="image && image.image_acne === 'done'"
    >
      <va-card class="flex xs12 mb-4" v-if="image && image.image_acne === 'done'">
        <va-card-content>
          <p>Left Cheek Acne Area count is: {{ left_acne_csv.length }}</p>
          <div class="flex md12" style="max-height: 360px; overflow-y: scroll;">
            <table id="pigment-table" style="width: 50%;">
              <th>
                <td>label</td>
              </th>
              <th>
                <td>area</td>
              </th>
              <th>
                <td>equivalent_diameter</td>
              </th>
              <th>
                <td>mean_intensity</td>
              </th>
              <th>
                <td>solidity</td>
              </th>
              <th>
                <td>Probabilty</td>
              </th>
              <th>
                <td>areaSizeSensor</td>
              </th>
              <th>
                <td>area_sqmm</td>
              </th>
              <tr v-for="row in left_acne_csv">
                <td v-for="cell in row">
                  {{ cell }}
                </td>
              </tr>
            </table>
          </div>
        </va-card-content>
      </va-card>
    </va-collapse>

    <va-card class="flex xs12 mb-4" v-if="image">
      <va-card-title style="font-size: 1.5rem;">Note</va-card-title>
      <va-card-content>
        <div class="flex md12">
          Mode - {{ modeList[image.mode] }} <br />
          Notes - {{ image.note }}
        </div>
      </va-card-content>
    </va-card>

    <div class="modal fade" id="configureFileModal" tabindex="-1" aria-labelledby="configureFileModal" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0;">
            Configure File
          </div>
          <div class="modal-body" v-if="image">
            <div class="md-12 configure-md row">
              <div class="md-6">
                <va-checkbox v-model="tiff_conversion" label="Convert to TIFF" :disabled="image.tiff_conversion !== 'na' && image.tiff_conversion !== 'failed'" />
              </div>
              <div class="md-6">
                <va-progress-circle size="small" indeterminate v-if="image.tiff_conversion !== 'na' && image.tiff_conversion !== 'done' && image.tiff_conversion !== 'failed'" />
                <va-chip size="small" :color="'success'" v-if="image.tiff_conversion === 'done'">Ready</va-chip>
                <va-chip size="small" :color="'danger'" v-else-if="image.tiff_conversion === 'failed'">Failed</va-chip>
                <va-icon :name="'fa4-refresh'" class="download-icon" style="padding-left: .5rem; cursor: pointer;" @click="retryConfigure('tiff_conversion')" v-if="image.tiff_conversion === 'done'" />
              </div>
            </div>
            <div class="md-12 configure-md configure-sub-md" v-if="tiff_conversion && image.tiff_conversion === 'na'">
              Do you want to use pp3 file?
              <va-checkbox v-model="tiff_pip3" label="Yes" />
            </div>
            <div class="md-12 configure-md row">
              <div class="md-6">
                <va-checkbox v-model="cropping" label="Crop the image" :disabled="(image.cropping !== 'na' && image.cropping !== 'failed') || (!tiff_conversion && image.tiff_conversion === 'na')" />
              </div>
              <div class="md-6">
                <va-progress-circle size="small" indeterminate v-if="image.cropping !== 'na' && image.cropping !== 'done' && image.cropping !== 'failed'" />
                <va-chip size="small" :color="'success'" v-if="image.cropping === 'done'">Ready</va-chip>
                <va-chip size="small" :color="'danger'" v-else-if="image.cropping === 'failed'">Failed</va-chip>
                <va-icon :name="'fa4-refresh'" class="download-icon" style="padding-left: .5rem; cursor: pointer;" @click="retryConfigure('cropping')" v-if="image.cropping === 'done'" />
              </div>
            </div>
            <div class="md-12 configure-md row">
              <div class="md-6">
                <va-checkbox v-model="image_processing" label="Process the image" :disabled="(image.image_processing !== 'na' && image.image_processing !== 'failed') || (!cropping && image.cropping === 'na')" />
              </div>
              <div class="md-6">
                <va-progress-circle size="small" indeterminate v-if="image.image_processing !== 'na' && image.image_processing !== 'done' && image.image_processing !== 'failed'" />
                <va-chip size="small" :color="'success'" v-if="image.image_processing === 'done'">Ready</va-chip>
                <va-chip size="small" :color="'danger'" v-else-if="image.image_processing === 'failed'">Failed</va-chip>
                <va-icon :name="'fa4-refresh'" class="download-icon" style="padding-left: .5rem; cursor: pointer;" @click="retryConfigure('image_processing')" v-if="image.image_processing === 'done'" />
              </div>
            </div>
            <!-- Feature not in use -->
            <!--
            <div class="md-12 configure-md configure-sub-md" v-if="image_processing && image.image_processing === 'na'">
              Do you want to process AHI code?
              <va-checkbox v-model="image_ahi" label="Yes" />
            </div>
            -->
            <div class="md-12 configure-md row">
              <div class="md-6">
                <va-checkbox v-model="image_pigment" label="Process the pigments" :disabled="(image.image_pigment !== 'na' && image.image_pigment !== 'failed') || (!image_processing && image.image_processing === 'na')" />
              </div>
              <div class="md-6">
                <va-progress-circle size="small" indeterminate v-if="image.image_pigment !== 'na' && image.image_pigment !== 'done' && image.image_pigment !== 'failed'" />
                <va-chip size="small" :color="'success'" v-if="image.image_pigment === 'done'">Ready</va-chip>
                <va-chip size="small" :color="'danger'" v-else-if="image.image_pigment === 'failed'">Failed</va-chip>
                <va-icon :name="'fa4-refresh'" class="download-icon" style="padding-left: .5rem; cursor: pointer;" @click="retryConfigure('image_pigment')" v-if="image.image_pigment === 'done'" />
              </div>
            </div>
            <div class="md-12 configure-md row">
              <div class="md-6">
                <va-checkbox v-model="image_acne" label="Process the acne lesions" :disabled="(image.image_acne !== 'na' && image.image_acne !== 'failed') || (!image_processing && image.image_processing === 'na')" />
              </div>
              <div class="md-6">
                <va-progress-circle size="small" indeterminate v-if="image.image_acne !== 'na' && image.image_acne !== 'done' && image.image_acne !== 'failed'" />
                <va-chip size="small" :color="'success'" v-if="image.image_acne === 'done'">Ready</va-chip>
                <va-chip size="small" :color="'danger'" v-else-if="image.image_acne === 'failed'">Failed</va-chip>
                <va-icon :name="'fa4-refresh'" class="download-icon" style="padding-left: .5rem; cursor: pointer;" @click="retryConfigure('image_acne')" v-if="image.image_acne === 'done'" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <va-button class="mr-2 mb-2" color="gray" data-bs-dismiss="modal">Cancel</va-button>
            <va-button class="mr-2 mb-2" color="primary" @click="configureFile" data-bs-dismiss="modal">Save</va-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load image
      const image = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/images/${this.fileId}`, { headers })
      this.image = image.data.image

      // Get image links
      const imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/images/${this.fileId}/download`, { headers })
      this.imageLink = imageLink.data.image
      this.images.push({
        thumbnail: this.imageLink.jpg,
        source: this.imageLink.jpg,
        title: 'RGB'
      })
      if (this.image.cropping === 'done') {
        this.images.push({
          thumbnail: this.imageLink.cropped,
          source: this.imageLink.cropped,
          title: 'Cropped RGB'
        })
      }
      if (this.image.image_processing === 'done') {
        this.images.push({
          thumbnail: this.imageLink.melanin_cropped,
          source: this.imageLink.melanin_cropped,
          title: 'ARMADA Skin Model – Melanin'
        })
        this.images.push({
          thumbnail: this.imageLink.hemoglobin_cropped,
          source: this.imageLink.hemoglobin_cropped,
          title: 'ARMADA Skin Model – Hemoglobin'
        })
      }
      if (this.image.image_pigment === 'done') {
        this.pigment_images.push({
          thumbnail: this.imageLink.pigment_crop,
          source: this.imageLink.pigment_crop,
          title: 'RGB Subset'
        })
        this.pigment_images.push({
          thumbnail: this.imageLink.pigment_melanin_crop,
          source: this.imageLink.pigment_melanin_crop,
          title: 'ARMADA Skin Model – Melanin Subset'
        })
        this.pigment_images.push({
          thumbnail: this.imageLink.pigment_analysis,
          source: this.imageLink.pigment_analysis,
          title: 'ARMADA Skin Model – Pigmentation Detection'
        })

        const response = await axios.get(this.imageLink.pigment_csv, { responseType: 'blob' })
        const csv = response.data
        csv.text().then((csvStr) => {
          csvStr.split('\n').forEach((row, i) => {
            if (i !== 0 && i !== (csvStr.split('\n').length - 1)) {
              const cells = []
              row.split(',').forEach((cell, j) => {
                if (j !== 0) {
                  cells.push(cell)
                }
              })
              this.pigment_csv.push(cells)
            }
          })
        })
      }
      if (this.image.image_acne === 'done') {
        this.left_acne_images.push({
          thumbnail: this.imageLink.acne_left_analysis_rgb,
          source: this.imageLink.acne_left_analysis_rgb,
          title: 'ARMADA Skin Model – Acne lesions detection - RGB'
        })
        this.left_acne_images.push({
          thumbnail: this.imageLink.acne_left_analysis,
          source: this.imageLink.acne_left_analysis,
          title: 'ARMADA Skin Model – Acne lesions detection'
        })

        let response = await axios.get(this.imageLink.acne_left_csv, { responseType: 'blob' })
        let csv = response.data
        csv.text().then((csvStr) => {
          csvStr.split('\n').forEach((row, i) => {
            if (i !== 0 && i !== (csvStr.split('\n').length - 1)) {
              const cells = []
              row.split(',').forEach((cell, j) => {
                if (j !== 0) {
                  cells.push(cell)
                }
              })
              this.left_acne_csv.push(cells)
            }
          })
        })

        this.right_acne_images.push({
          thumbnail: this.imageLink.acne_right_analysis_rgb,
          source: this.imageLink.acne_right_analysis_rgb,
          title: 'ARMADA Skin Model – Acne lesions detection - RGB'
        })
        this.right_acne_images.push({
          thumbnail: this.imageLink.acne_right_analysis,
          source: this.imageLink.acne_right_analysis,
          title: 'ARMADA Skin Model – Acne lesions detection'
        })

        response = await axios.get(this.imageLink.acne_right_csv, { responseType: 'blob' })
        csv = response.data
        csv.text().then((csvStr) => {
          csvStr.split('\n').forEach((row, i) => {
            if (i !== 0 && i !== (csvStr.split('\n').length - 1)) {
              const cells = []
              row.split(',').forEach((cell, j) => {
                if (j !== 0) {
                  cells.push(cell)
                }
              })
              this.right_acne_csv.push(cells)
            }
          })
        })

        this.acne_image = {
          thumbnail: this.imageLink.acne_outline,
          source: this.imageLink.acne_outline,
          title: 'ARMADA Skin Model – Acne ROI'
        }
      }
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.sessionId}/files/${this.fileId}` } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      session: {},
      imageLink: null,
      image: null,
      id: this.$route.params.id,
      sessionId: this.$route.params.sessionId,
      fileId: this.$route.params.fileId,
      tiff_conversion: false,
      cropping: false,
      image_processing: false,
      image_pigment: false,
      image_acne: false,
      tiff_pip3: true,
      image_ahi: false,
      images: [],
      pigment_images: [],
      left_acne_images: [],
      right_acne_images: [],
      acne_image: {},
      pigment_csv: [],
      left_acne_csv: [],
      right_acne_csv: [],
      options: {
        url: 'data-src'
      },
      modeList: {
        'FFFFFFFF00000000': 'Cross Polarized',
        '00000000FFFFFFFF': 'Linear Polarized',
        '0000000000000000': 'LEDs Off'
      },
      collapse_values: [false,false,false,false]
    }
  },
  methods: {
    downloadFiles: async function () {
      this.$vaToast.init({
        message: 'Downloading Files. Please wait.',
        iconClass: 'fa-star-o',
        position: 'bottom-right',
        duration: 6000,
        fullWidth: false,
        color: 'primary',
      })
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Get image links
        const imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/images/${this.fileId}/download`, { headers })

        axios({
            url: imageLink.data.image.txt,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'text/plain'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}.txt`
            link.click()
          })

        axios({
            url: imageLink.data.image.jpg,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}.jpg`
            link.click()
          })

        axios({
            url: imageLink.data.image.cropped,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_crop.jpg`
            link.click()
          })

        axios({
            url: imageLink.data.image.dng,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/DNG'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}.dng`
            link.click()
          })

        axios({
            url: imageLink.data.image.tif,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/tiff'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}.tif`
            link.click()
          })

        axios({
            url: imageLink.data.image.cropped_tif,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/tiff'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_crop.tif`
            link.click()
          })

        axios({
            url: imageLink.data.image.hemoglobin_cropped,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_hemoglobin_crop.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.melanin_cropped,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_melanin_crop.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.hemoglobin_cropped_tif,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/tiff'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_hemoglobin_crop.tif`
            link.click()
          })

        axios({
            url: imageLink.data.image.melanin_cropped_tif,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/tiff'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_melanin_crop.tif`
            link.click()
          })

        axios({
            url: imageLink.data.image.pigment_crop,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_pigment_crop.jpg`
            link.click()
          })

        axios({
            url: imageLink.data.image.pigment_melanin_crop,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_pigment_melanin_crop.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.pigment_analysis,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_pigment_analysis.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.pigment_csv,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'text/csv'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_pigment_csv.csv`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_left_crop,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_left_crop.jpg`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_left_analysis,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_left_analysis.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_left_analysis_rgb,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_left_analysis_rgb.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_left_csv,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'text/csv'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_left_csv.csv`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_right_crop,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_right_crop.jpg`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_right_analysis,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_right_analysis.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_right_analysis_rgb,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/png'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_right_analysis_rgb.png`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_right_csv,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'text/csv'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_right_csv.csv`
            link.click()
          })

        axios({
            url: imageLink.data.image.acne_outline,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/jpg'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_acne_roi.jpg`
            link.click()
          })

        axios({
            url: imageLink.data.image.mask,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/tiff'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_mask.tif`
            link.click()
          })

        axios({
            url: imageLink.data.image.landmarks,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: 'image/tiff'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.image.name.split('.')[0]}_landmarks.tif`
            link.click()
          })
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.sessionId}/files/${this.fileId}` } })
      }
    },
    configureFile: async function () {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {}
        if (this.tiff_conversion) {
          payload.tiff_conversion = 'pending'
          payload.tiff_pip3 = this.tiff_pip3
        }
        if (this.cropping) {
          payload.cropping = 'pending'
        }
        if (this.image_processing) {
          payload.image_processing = 'pending'
          payload.image_ahi = false // Always Off
        }
        if (this.image_pigment) {
          payload.image_pigment = 'pending'
        }
        if (this.image_acne) {
          payload.image_acne = 'pending'
        }
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/images/${this.fileId}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'File configured successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.loader = this.$loading.show()
        // Load image
        const image = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/images/${this.fileId}`, { headers })
        this.image = image.data.image
        this.loader.hide()
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.sessionId}/files/${this.fileId}` } })
      }
    },
    retryConfigure: async function (option) {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {}
        payload[option] = 'pending'
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/images/${this.fileId}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'File retry configured!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })
        this.loader = this.$loading.show()
        // Load image
        const image = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/images/${this.fileId}`, { headers })
        this.image = image.data.image
        this.loader.hide()
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.sessionId}/files/${this.fileId}` } })
      }
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .download-icon:hover {
    color: #154EC1;
  }

  #stat-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .configure-md {
    padding-top: 1rem;
  }

  .configure-sub-md {
    padding-left: 2rem;
  }

  .image {
    cursor: pointer;
    width: 100%;
  }

  .image2 {
    cursor: pointer;
    width: 45%;
    margin-right: 1rem;
    margin-left: 0.75rem;
  }

  .image3 {
    cursor: pointer;
    width: 45%;
  }

  .image4 {
    cursor: pointer;
    width: 45%;
    margin-left: 28%;
  }

  #pigment-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #pigment-table tr > td {
    border: 1px solid #ddd;
    padding: 8px;
  }
</style>
